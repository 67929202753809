import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Brian Green"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Brian Green"
        videoUrl="https://vimeo.com/498089798/d8e7f56de0"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.brianGreenImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box flex="2">
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Brian Green
            </Text>
            <Text level="1" mb="1.2rem">
              Touring and session guitarist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/bgreensurfs" as="a">
                <Instagram />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Brian Green is a guitar player and producer based out of Los
              Angeles, California. He has toured, performed and/or recorded with
              artists including{" "}
              <strong>
                John Legend, Rufus Wainwright, Michael Bublé, Nayo,
              </strong>{" "}
              and <strong>Kat Mcphee</strong>. Brian has also worked as a guitar
              player for television and award shows including{" "}
              <strong>Dancing With The Stars, American Idol</strong> and the{" "}
              <strong>Emmys</strong>.
            </Text>
            <Text level="2">
              Additionally, he records and performs with the band{" "}
              <strong>Pomplamoose</strong>, releasing weekly videos with guests
              including <strong>Inara George</strong> of{" "}
              <strong>Bird and the Bee</strong> and{" "}
              <strong>Kina Grannis</strong>. As a producer, Brian has worked
              with various artists, including{" "}
              <strong>Rozzi Crane, VINCINT</strong> and{" "}
              <strong>Lily Kershaw</strong> as well as making commercial music
              for major brands and production music houses. Brian also releases
              music under his own name, including his latest instrumental
              release, Impressions For Headphones.
            </Text>
          </Box>
          
        </Box>
      </Width>
      <MentorFooter data={data} name="Brian Green" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    brianGreenImage: file(
      relativePath: { eq: "brianGreen-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
